import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { navigate } from "gatsby"
import {isBrowser} from "../helper/globals"
import TCContent from "../components/dashboard/terms-content"

const Terms = (props) => (
  <Layout title="Terms & Policy" >
  <>
    <Seo title="Terms & Policy" />
    <div style={{width:'100%',marginBottom:'10px', marginTop:'20px', marginLeft:'10px'}}>
      <span style={{float:'left', marginTop:'10px'}}><p><b>Terms of Service and Policy for <a href="https://121islam.com">121islam.com </a>:</b></p></span>
      
    </div>
    <section className="content-section" >
      <div className="container tc">
        <br/><br/><br/>
        <TCContent />
      </div>
    </section>   
    </>  
  </Layout>
)

export default Terms
