import React from "react"
import {Cookies} from "react-cookie"

const TCContent = () => {

  let cookie = new Cookies();

    var liveVersion = cookie.get('appVersion');
    //console.log("liveVersion::",liveVersion);
    if(liveVersion!==undefined && liveVersion!=='' && liveVersion!==null){
        liveVersion = liveVersion.split('-');
        if(parseInt(liveVersion[0])<=1 && parseInt(liveVersion[1])<=17){
            liveVersion= true;
        }else{
            liveVersion = false;
        }
    }else{
        liveVersion = true;
    }

  return (
    <>
      <p>
        Welcome to <a href="https://121islam.com">121islam.com</a>.
        By using this website or the associated mobile application
        (&quot;App&quot;), you agree to be bound by the following terms and
        conditions (&quot;Terms of Service&quot;). Please read them carefully
        before using this website or App.
      </p>

      <p>
        <b>1. Acceptance of Terms</b>
      </p>

      <p>
        By accessing this website or App, you agree to these Terms and
        Conditions, all applicable laws, and regulations. If you do not agree to
        these terms and conditions, you are prohibited from using or accessing
        this site.
      </p>

      <p>
        <b>2. Use License</b>
      </p>

      <p>
        Permission is granted to temporarily download one copy of the materials
        (information or software) on the website or App for personal,
        non-commercial transitory viewing only. This is the grant of a license,
        not a transfer of title, and under this license, you may not:
      </p>

      <p>
        * modify or copy the materials; <br />* use the materials for any
        commercial purpose or for any public display (commercial or
        non-commercial); <br />* attempt to decompile or reverse engineer any
        software contained on the website or App; <br />* remove any copyright
        or other proprietary notations from the materials; or <br />* transfer
        the materials to another person or &quot;mirror&quot; the materials on
        any other server.
      </p>

      <p>
        This account shall automatically terminate if you violate any of these
        restrictions and may be terminated by 121islam.com at any time.
        Upon terminating your viewing of these materials or upon the termination
        of this license, you must destroy any downloaded materials in your
        possession, whether in electronic or printed format.
      </p>

      <p>
        <b>3. User Conduct</b>
      </p>

      <p>
        You are solely responsible for any and all acts and omissions that occur
        under your account or password, and you agree not to engage in
        unacceptable use of the website or App, which includes, without
        limitation, use of the website or App to:
      </p>

      <p>
        * Post, transmit or otherwise make available any content that is
        unlawful, harmful, threatening, abusive, harassing, tortious,
        defamatory, vulgar, obscene, libelous, invasive of another's privacy,
        hateful, or racially, ethnically, or otherwise objectionable; <br />*
        Impersonate any person or entity, or falsely state or otherwise
        misrepresent your affiliation with a person or entity; <br />* Forge
        headers or otherwise manipulate identifiers in order to disguise the
        origin of any content transmitted through the website or App; <br />*
        Upload, post, email, or otherwise transmit any material that contains
        software viruses or any other computer code, files, or programs designed
        to interrupt, destroy, or limit the functionality of any computer
        software or hardware or telecommunications equipment; <br />* Collect or
        store personal data about other users; <br />* Violate any applicable
        laws or regulations.
      </p>

      <p>
        <b>4. Information We Collect</b>
      </p>
      <p>
        Types of Information: We may collect various types of personal
        information, including but not limited to:
        <br />* Name: We collect your name to personalize your user experience
        and to identify you within the app.
        <br />* Phone Number: We collect your phone number to create and verify
        your account and facilitate communication with teachers and support.
        <br />* Email Address: Your email address is used for communication,
        account recovery, and important notifications.
        <br />* Sex: We collect your gender for a more personalized educational
        experience, such as recommending suitable teachers and content.
        <br />* Date of Birth: We collect your date of birth to verify your age
        and ensure compliance with our policy regarding lessons with teachers of
        the opposite gender.
        <br />* Schedules: We collect your schedules to enable you to book
        lessons and manage your learning activities efficiently.
        {liveVersion && <><br />* Payment Information: If you choose to make payments for lesson
        hours, we will collect payment information, including credit card or
        debit card details. Please note that we do not store full payment card
        information on our servers. Payment processing is securely handled by
        third-party payment processors.</>}
      </p>
      <p>
        <b>5. How We Use Your Information</b>
      </p>
      <p>
        Purpose of Data Processing: We use your personal information for various
        purposes, including but not limited to:
        <br />* To create and manage your user account.
        <br />* To provide you with access to our educational services,
        including scheduling lessons.
        <br />* To personalize your experience by recommending teachers and
        content.
        <br />* To facilitate communication with teachers and support.
        {liveVersion && <><br />* To process payments securely and manage your account balances.</>}
        <br />* To send you important notifications, updates, and promotional
        offers related to our services.
      </p>
      <p>
        <b>6. Data Security</b>
      </p>
      <p>
        Protecting Your Data: We take data security seriously and implement
        reasonable measures to protect your personal information. This includes
        encryption of sensitive data during transmission and secure storage of
        data on our servers. However, no method of data transmission over the
        internet or electronic storage is completely secure, and we cannot
        guarantee absolute security.
      </p>

      <p>
        <b>7. Sharing of Information</b>
      </p>
      <p>
        Third-Party Service Providers: We do not sell, rent, or trade your
        personal information to third parties for marketing purposes. We may
        share your information with trusted third-party service providers who
        assist us in delivering our services{liveVersion && <>, such as payment processors</>} and
        customer support services. These providers are contractually bound to
        keep your information confidential and secure.
      </p>

      <p>
        <b>8. Your Choices and Rights</b>
      </p>
      <p>
        Managing Your Data: You have certain rights regarding your personal
        information, including the right to access, correct, or delete your
        data. You can also manage your communication preferences and opt-out of
        promotional emails. To exercise your rights or make inquiries about your
        data, please contact us at contact@121islam.com
      </p>

      <p>
        <b>9. Account Deletion and Lesson Cancellations</b>
      </p>
      <p>
        Account Deletion: Upon account deletion, any remaining funds held within
        your account will be irrevocably forfeited. Lesson Cancellations: There
        are no refunds for lesson cancellations made in close proximity to the
        scheduled lesson time (3 hours) or for non-attendance at a scheduled
        lesson.
      </p>

      <p>
        <b>10. Limitations</b>
      </p>
      <p>
        In no event shall 121islam.com or its suppliers be liable for any
        damages (including, without limitation, damages for loss of data or
        profit, or due to business interruption) arising out of the use or
        inability to use the materials on the website or App, even if
        121islam.com or a 121islam.com authorized representative has
        been notified orally or in writing of the possibility of such damage.
      </p>

      <p>
        <b>11. Revisions and Errata</b>
      </p>
      <p>
        The materials appearing on the website or App could include technical,
        typographical, or photographic errors. 121islam.com does not
        warrant that any of the materials on its website or App are accurate,
        complete, or current. 121islam.com may make changes to the
        materials contained on its website or App at any time without notice.
        121islam.com does not, however, make any commitment to update the
        materials.
      </p>

      <p>
        <b>12. Links</b>
      </p>
      <p>
        121islam.com has not reviewed all of the sites linked to its
        website or App and is not responsible for the contents of any such
        linked site. The inclusion of any link does not imply endorsement by
        121islam.com
      </p>

      <p>
        <b>13. Changes to Terms and Policy</b>
      </p>
      <p>
        Policy Updates: We may update these Terms of Service and Privacy Policy
        from time to time to reflect changes in our practices or for legal and
        regulatory reasons. We will notify you of any significant changes by
        email or through the app.
      </p>

      <p>
        <b>14. Contact Us</b>
      </p>
      <p>
        Getting in Touch: If you have questions or concerns regarding these
        Terms of Service and Privacy Policy or our data practices, please
        contact us at contact@121islam.com
      </p>
    </>
  )
}

export default TCContent;